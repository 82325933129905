import './App.css';
import logo from './logo.png';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        🚀 iiraa is Coming Soon! 🐘
        <div className='img'>
          <img src={logo} alt=''/>
        </div>
      </header>
     
    </div>
  );
}

export default App;
